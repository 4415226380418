<template>
  <section>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>
    <div v-else>
      <div>
        <participant-general
          v-if="user"
          :user="user"
          @update="onUserUpdated"
          @remove="removeUser"
        />
      </div>
      <b-tabs
        v-model="tabIndex"
        pills
        card
        content-class="tab-custom"
        nav-wrapper-class="nav-top"
      >
        <b-tab
          v-if="!isProgramTypeTraining"
          lazy
        >
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Matches</span>
          </template>
          <b-card>
            <participant-matches
              v-if="user"
              :user="user"
            />
          </b-card>
        </b-tab>
        <b-tab
          v-if="isProgramTypeTraining"
          lazy
        >
          <!-- title -->
          <template #title>
            <feather-icon
              icon="MailIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Training</span>
          </template>
          <participant-trainings :user="user" />
        </b-tab>
        <b-tab lazy>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="MailIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Communications</span>
          </template>
          <participant-communications :user="user" />
        </b-tab>
        <b-tab
          v-if="!isProgramTypeTraining"
          lazy
        >
          <!-- title -->
          <template #title>
            <feather-icon
              icon="BookOpenIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Surveys</span>
          </template>
          <participants-surveys
            :user="user"
          />
        </b-tab>
        <!--/ notes tab -->
        <b-tab
          v-if="!isProgramTypeTraining"
          lazy
        >
          <!-- title -->
          <template #title>
            <feather-icon
              icon="EditIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Notes</span>
          </template>

          <!-- Tab content -->
          <participant-notes
            v-if="user"
            :user="user"
          />
        </b-tab>
        <b-tab
          v-if="isUserMentor"
          lazy
        >
          <!-- title -->
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold"> {{ roleAlias(userRoles.MENTOR, defaultProgram) }} Application</span>
          </template>
          <b-card>
            <aom-languages>
              <template slot-scope="props">
                <participants-application-answers
                  :user="user"
                  :role-id="mentorRoleId"
                  :index="props.index"
                  :locale-id="props.localeId"
                />
              </template>
            </aom-languages>
          </b-card>
        </b-tab>
        <b-tab
          v-if="isUserMentee"
          lazy
        >
          <!-- title -->
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold"> {{ roleAlias(userRoles.MENTEE, defaultProgram) }} Application</span>
          </template>
          <b-card>
            <aom-languages>
              <template slot-scope="props">
                <participants-application-answers
                  :user="user"
                  :role-id="menteeRoleId"
                  :index="props.index"
                  :locale-id="props.localeId"
                />
              </template>
            </aom-languages>
          </b-card>
        </b-tab>
        <b-tab lazy v-if="isProgramPartnerPreferencing">
          <!-- title -->
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Partner Preferencing</span>
          </template>
          <b-card>
            <participants-preferred-partners
              :user="user"
            />
          </b-card>
        </b-tab>

        <!-- groups tab -->
        <b-tab
          v-if="!isProgramTypeTraining"
          lazy
        >
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Groups</span>
          </template>
          <b-card>
            <participant-groups
              v-if="user"
              :user="user"
            />
          </b-card>
        </b-tab>
        <!--/ groups tab -->
      </b-tabs>
    </div>
  </section>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue';
import ParticipantGeneral from './ParticipantGeneral.vue';
import ParticipantMatches from './ParticipantMatches.vue';
import ParticipantNotes from './ParticipantNotes.vue';
import ParticipantGroups from './ParticipantGroups.vue';
import usersService from "@/services/usersService";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import AomLanguages from "@aom-core/AomLanguages";
import ParticipantsApplicationAnswers from './ParticipantsApplicationAnswers.vue';
import { userRoles, programTypes } from "@models";
import ParticipantsSurveys from './ParticipantsSurveys.vue';
import ParticipantCommunications from './ParticipantsCommunication.vue';
import ParticipantTrainings from './ParticipantTrainings.vue';
import ParticipantsPreferredPartners from './ParticipantsPreferredPartners.vue';
import { trainingStatus } from '@/models';
import { roleAlias } from '@/@aom-core/utils/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'Participant',
  components: {
    BTabs,
    BTab,
    BCard,
    ParticipantGeneral,
    ParticipantMatches,
    AomSkeletonLoader,
    ParticipantGroups,
    AomLanguages,
    ParticipantsApplicationAnswers,
    ParticipantsSurveys,
    ParticipantCommunications,
    ParticipantNotes,
    ParticipantsPreferredPartners,
    ParticipantTrainings
  },
  data() {
    return {
      isLoading: false,
      user: undefined,
      tabIndex: 0,
      tabs: []
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgram']),
    mentorRoleId() {
      return userRoles.MENTOR;
    },
    menteeRoleId() {
      return userRoles.MENTEE;
    },
    isUserMentee () {
      return this.user?.user_roles.filter(r => r.role_id === userRoles.MENTEE).length > 0;
    },
    isUserMentor() {
      return this.user?.user_roles.filter(r => r.role_id === userRoles.MENTOR).length > 0;
    },
    isProgramPartnerPreferencing () {
      return this.defaultProgram && this.defaultProgram.type_id === programTypes.PARTNER_PREFERENCING;
    },
    isProgramTypeTraining () {
      return this.defaultProgram && this.defaultProgram.type_id === programTypes.TRAINING;
    },
  },
  watch: {
    tabIndex: {
      handler() {
        const tabName = this.tabs.find((tab,i) => i === this.tabIndex);
        if(this.$route.hash !== tabName) {
          this.$router.push({ hash: `${tabName}` });
        }
      }, 
    },
    '$route.hash': {
      handler(n) {
        if(n) {
          const tabIndex = this.tabs.findIndex(tab => n === tab);
          this.tabIndex = tabIndex;
        }
      },
      deep: true,
      immediate: true
    },
    '$route.params.participantId': {
      handler(n) {
        if(n) {
          this.fetchUser();
        }
      }
    }
  },
  async created() {
    await this.fetchUser();
    if (this.isUserMentor && this.isUserMentee) {
      this.tabs = ['#matches', '#communications', '#surveys', '#notes', '#mentorapp', '#menteeapp', '#partnerpref', '#groups'];
    } else if (this.isUserMentee) {
      this.tabs = ['#matches', '#communications', '#surveys', '#notes', '#menteeapp', '#partnerpref', '#groups'];
    } else if (this.isUserMentor) {
      this.tabs = ['#matches', '#communications', '#surveys', '#notes', '#mentorapp', '#partnerpref', '#groups'];
    }

    if (!this.isProgramPartnerPreferencing) {
      this.tabs = this.tabs.filter(tab => tab !== '#partnerpref');
    }
    if (this.isProgramTypeTraining) {
      this.tabs = ['#trainings', '#communications'];
    }
    await this.$store.dispatch('app/FETCH_APP_LANGUAGES');
  },
  methods: {
    onUserUpdated() {
      this.fetchUser();
    },
    removeUser() {
      this.$router.push({ name: 'champion-participants' });
    },
    async fetchUser() {
      const programId = this.$route.params.id;
      const participantId = this.$route.params.participantId;
      try {
        this.isLoading = true;
        const user = await usersService.getProgramUser(programId, participantId);

        const mentorTrainings = user.data.program_training_users
          ? user.data.program_training_users.filter(row => {
            return !!row.training?.roles?.find(role => role.id === userRoles.MENTOR);
          })
          : [];
        const inCompleteMentorTrainings = mentorTrainings
          ? mentorTrainings.filter(row => row.status?.id !== trainingStatus.COMPLETE)
          : [];
        const mentorTrainingStatus = inCompleteMentorTrainings.length > 0
          ? 'Incomplete'
          : (mentorTrainings.length > 0 ? 'Complete' : 'N/A');


        const menteeTrainings = user.data.program_training_users
          ? user.data.program_training_users.filter(row => {
            return !!row.training?.roles?.find(role => role.id === userRoles.MENTEE);
          })
          : [];
        const inCompleteMenteeTrainings = menteeTrainings
          ? menteeTrainings.filter(row => row.status?.id !== trainingStatus.COMPLETE)
          : [];
        const menteeTrainingStatus = inCompleteMenteeTrainings.length > 0
          ? 'Incomplete'
          : (menteeTrainings.length > 0 ? 'Complete' : 'N/A');

        this.user = {
          ...user.data,
          mentorTraining: mentorTrainingStatus,
          menteeTraining: menteeTrainingStatus,
        };
      } catch (e) {
        const { status} = e.response;
        if (status === 404 ) {
          this.$toast(makeErrorToast('User not found'));
          return;
        }
        this.$toast(makeErrorToast('Unable to fetch participant.'));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  setup() {
    return {
      roleAlias,
      userRoles
    };
  }
};
</script>
<style lang="scss" scoped>
::v-deep .card-header {
  background-color: unset;
  border-bottom: none;
  padding-left: 0;
  padding-right: 0;
  .card-header-pills {
    margin: 0;
  }
}
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
</style>